<template>
  <!-- 服务费率拆分及其他输入框组件 -->
  <div class="financingInput">
    <!-- 输入框直接输入时 -->
    <template v-if="serviceInput">
      <proportionInput
        v-if="!$attrs.disabled"
        v-bind="$attrs"
        v-on="$listeners"
        style="flex: 1"
      ></proportionInput>
      <el-input v-else v-bind="$attrs" v-on="$listeners"></el-input>
    </template>
    <!-- 使用弹窗添加多个时 -->
    <template v-else>
      <el-input v-if="$attrs.sum" v-bind="$attrs" v-on="$listeners"></el-input>
      <el-input v-else v-bind="$attrs" v-on="$listeners"></el-input>
    </template>
    <span class="unit">{{ $attrs.unitName }}</span
    ><span class="btn" v-if="$attrs.showBtn" @click="show"
      ><i class="iconfont iconchaifen"></i
    ></span>
    <span class="btn" v-if="serviceInput && $attrs.disabled" @click="removeData"
      ><i class="iconfont iconshanchu1"></i
    ></span>
    <!-- 拆分弹窗 -->
    <base-dialog
      :visible.sync="visible"
      width="800px"
      title="拆分"
      :showFooter="false"
      class="financingInput"
    >
      <div style="padding: 20px 10px">
        <div class="addbtn">
          <div class="addbutton" @click="addTable">
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <base-table
          :columns="columns"
          :showPage="false"
          :tableAttrs="{
            data: tableData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          ref="tableData"
          :webPage="true"
        >
          <template slot="index" slot-scope="scope">{{
            scope.$index + 1
          }}</template>
          <template slot="chargeParty" slot-scope="scope">
            <base-select
              v-model="scope.row.chargeParty"
              placeholder="请选择"
              :options="payeeData"
              :selectedField="['enterpriseName', 'enterpriseName']"
            />
            <!-- <el-input
              placeholder="请输入"
              maxlength="20"
              v-model="scope.row.chargeParty"
            ></el-input> -->
          </template>
          <template slot="chargeRate" slot-scope="scope">
            <proportion-input
              placeholder="请输入"
              v-model="scope.row.chargeRate"
              maxlength="5"
              @input="blurChargeRate(scope.row.chargeRate, scope.$index)"
            ></proportion-input>
          </template>
          <template slot="action" slot-scope="scope">
            <IconButton
              @click="remove(scope.$index)"
              content="删除"
              icon="iconfont iconshanchu1"
            ></IconButton>
          </template>
        </base-table>
      </div>
      <template slot="footer">
        <base-button label="提 交" @click="submit"></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="visible = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseSelect from '@/components/common/base-select/base-select.vue'
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import proportionInput from '@/components/input/proportion-input/proportion-input'
export default {
  name: 'financingInput',
  components: {
    baseDialog,
    BaseTable,
    BaseButton,
    IconButton,
    proportionInput,
    BaseSelect
  },
  props: {
    payeeData: Array,
    schemeInfoList: Array,
    serviceInput: Boolean
  },
  data () {
    return {
      options: [],
      visible: false,
      tableData: [
        {
          chargeParty: '',
          chargeRate: ''
        }
      ],
      columns: [
        {
          label: '序号',
          prop: 'index'
        },
        {
          label: '收款主体',
          prop: 'chargeParty'
        },
        {
          label: '服务费率(%)',
          prop: 'chargeRate'
        },
        {
          label: '操作',
          prop: 'action'
        }
      ]
    }
  },
  methods: {
    show () {
      this.tableData = this.schemeInfoList[this.$attrs.keyIndex]
        .finServiceCollectInfo
        ? JSON.parse(
          JSON.stringify(
            this.schemeInfoList[this.$attrs.keyIndex].finServiceCollectInfo
          )
        )
        : [
          {
            chargeParty: '',
            chargeRate: ''
          }
        ]

      this.visible = true
    },
    // 添加
    addTable () {
      this.tableData.push({
        chargeParty: '',
        chargeRate: ''
      })
    },
    blurChargeRate (data, index) {
      data = data.replace(/^[0]+[0-9]*$/gi, '')
      if (parseFloat(data) === 100) {
        this.tableData[index].chargeRate = '10'
      } else {
        this.tableData[index].chargeRate = data
      }
      if (
        this.tableData[index].chargeRate.indexOf('0.') !== -1 &&
        this.tableData[index].chargeRate.substr(0, 1) === '0'
      ) {
        this.tableData[index].chargeRate = ''
      }
    },
    // 删除
    remove (index) {
      this.tableData.splice(index, 1)
    },
    submit () {
      let status = true
      this.tableData.forEach((ele) => {
        if (ele.chargeParty === '' || ele.chargeRate === '') {
          status = false
        }
      })
      if (!status) {
        return this.warning('请完善信息')
      }
      let num = 0
      this.tableData.forEach((ele) => {
        num = num + parseFloat(ele.chargeRate)
      })
      if (num > 100) {
        return this.warning('服务费率之和不能大于100%')
      }
      this.tableData.forEach((item) => {
        this.payeeData.forEach((elem) => {
          if (item.chargeParty === elem.enterpriseName) {
            item.chargePartyId = elem.keyId
          }
        })
      })
      const ids = this.tableData.map(value => value.chargePartyId)
      const idsSet = new Set(ids)
      if (idsSet.size !== ids.length) {
        this.warning('收费主体重复')
        return false
      }
      this.$emit('serviceTariffing', {
        data: this.tableData,
        key: this.$attrs.keyIndex
      })
      this.visible = false
    },
    removeData () {
      this.$emit('serviceTariffing', {
        data: [],
        key: this.$attrs.keyIndex
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.addbtn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
}
.addbutton {
  background: #4A7CF0;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    background-color: #6996FF;
  }
}
.financingInput {
  display: flex;
  .el-input {
    flex: 1;
  }
  .unit {
    margin-left: 10px;
  }
  .btn {
    cursor: pointer;
    margin-left: 5px;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%);

    border-radius: 50%;
    color: #fff;
    /deep/ .iconchaifen {
      font-size: 20px;
    }
  }
}
.el-icon {
  cursor: pointer;
}
.financingInput /deep/ .el-dialog {
  height: 60vh !important;
}
/deep/ .el-input__validateIcon{
  display: none;
}
</style>
